<template>
  <div>
    <div
      v-if="
        typeof error_messages === 'object' &&
          Object.keys(error_messages).length > 0
      "
      class="alert alert-danger"
    >
      <ul>
        <template v-for="(error, i) in error_messages">
          <span v-bind:key="i">
            <li v-for="(message, ii) in error" v-bind:key="ii">
              {{ message }}
            </li>
          </span>
        </template>
      </ul>
    </div>
    <div class="tabs">
      <v-tabs
        v-model="tab"
        background-color="light primary"
        class="elevation-2"
        dark
      >
        <v-tab>{{ $t("COMPONENTS.WAREHOUSE") }}</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item>
          <v-card flat>
            <v-card-text>
              <vue-form-generator
                ref="form"
                :schema="warehouseSchema"
                :model="warehouse"
                :options="formOptions"
              ></vue-form-generator>

              <v-btn
                @click="save()"
                class="btn btn-success btn-elevate kt-login__btn-primary text-white"
                >{{ $t("BUTTON.SAVE") }}
              </v-btn>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";

import VueFormGenerator from "vue-form-generator";
import "vue-form-generator/dist/vfg.css"; // optional full css additions

import {
  GET_WAREHOUSE,
  SAVE_WAREHOUSE,
  UPDATE_WAREHOUSE
} from "@/store/warehouses.module";

import { mapGetters, mapState } from "vuex";
// Vue.use(VueFormGenerator)

import _ from "lodash";

export default {
  name: "createUpdate",

  components: {
    "vue-form-generator": VueFormGenerator.component
  },
  computed: {
    ...mapGetters(["isLoadingWarehouses"]),
    ...mapState({
      errors: state => state.auth.errors
    })
  },
  data() {
    return {
      error_messages: [],
      model: "warehouses",
      tab: 0,
      warehouse: {
        name: null,
        qIB: null,
        primaryQIB: null,
        primaryQuantity: null,
        priceUnder: null,
        priceOver: null
      },
      warehouseSchema: {
        fields: [
          {
            type: "input",
            inputType: "text",
            label: "Име",
            model: "name",
            required: true,
            validator: VueFormGenerator.validators.string.locale({
              fieldIsRequired: "Полето е задължително !!!"
            })
          }
        ]
      },
      formOptions: {
        validateAfterLoad: false,
        validateAfterChanged: false,
        validateAsync: true
      }
    };
  },
  mounted() {
    let vm = this;

    _.delay(function() {
      var current_page = "Добави";
      if (typeof vm.$route.params.id !== "undefined") {
        current_page = "Промени";
      }
      vm.$store.dispatch(SET_BREADCRUMB, [
        { title: "Складове", route: "/warehouses" },
        { title: current_page }
      ]);
    }, 300);

    if (typeof this.$route.params.id !== "undefined") {
      this.getData();
    }
  },
  methods: {
    async save() {
      let vm = this;

      vm.error_messages = [];

      let errors = await this.$refs.form.validate();

      if (errors.length) {
        return;
      }

      var action =
        typeof this.$route.params.id !== "undefined"
          ? UPDATE_WAREHOUSE
          : SAVE_WAREHOUSE;
      this.error_messages = [];

      vm.$store
        .dispatch(action, { payload: vm.warehouse })
        .then(data => {
          vm.$notification["success"]({
            message: data.status,
            description: data.message
          });

          vm.$router.push({ name: "warehousesList" });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });

          if (response.status === 404) {
            vm.$notification["success"]({
              message: response.status,
              description: response.message
            });
            vm.$router.push({ name: "warehousesList" });
          }
        });
    },
    getData: function() {
      let vm = this;
      this.error_messages = [];
      let id = this.$route.params.id;
      this.$store
        .dispatch(GET_WAREHOUSE, id)
        .then(data => {
          vm.$nextTick(function() {
            vm.warehouse = data.data;
          });
        })
        .catch(response => {
          vm.error_messages = response.data.errors;
          vm.$scrollTo("html", 420, {
            offset: -300
          });

          if (typeof response === "undefined" || response.status === 404) {
            vm.$notification["error"]({
              message: "Грешка 404",
              description: "Страницата не е намерена!"
            });
            vm.$router.push({ name: "warehousesList" });
          }
        });
    }
  }
};
</script>
